var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"filters__condition-item",class:{ 'filters__condition-item--loading': _vm.filter._?.loading }},[_c('div',{staticClass:"filters__field"},[_c('Dropdown',{attrs:{"value":_vm.filter.field,"disabled":_vm.disableFilter,"fixed-items":true},on:{"input":function($event){return _vm.$emit('updateFilter', { field: $event })}}},_vm._l((_vm.fields),function(field){return _c('DropdownItem',{key:'field-' + field.id,attrs:{"name":field.name,"value":field.id,"disabled":!_vm.hasCompatibleFilterTypes(field, _vm.filterTypes)}})}),1)],1),_vm._v(" "),_c('div',{staticClass:"filters__type"},[_c('Dropdown',{attrs:{"disabled":_vm.disableFilter,"value":_vm.filter.type,"fixed-items":true},on:{"input":function($event){return _vm.$emit('updateFilter', { type: $event })}}},_vm._l((_vm.allowedFilters(_vm.filterTypes, _vm.fields, _vm.filter.field)),function(fType){return _c('DropdownItem',{key:fType.type,attrs:{"name":fType.getName(),"value":fType.type,"visible":fType.isDeprecated() === false || fType.type === _vm.filter.type}})}),1)],1),_vm._v(" "),_c('div',{staticClass:"filters__value",class:{
      'filters__value--with-input-field': _vm.hasAfterValueInputContent,
    }},[_vm._t("filterInputComponent",function(){return [(_vm.fieldCanBeFiltered(_vm.fields, _vm.filter))?_c(_vm.getInputComponent(_vm.filter.type, _vm.filter.field),{ref:"filter-value",tag:"component",attrs:{"filter":_vm.filter,"view":_vm.view,"is-public-view":_vm.isPublicView,"fields":_vm.fields,"disabled":_vm.disableFilter,"read-only":_vm.readOnly},on:{"input":function($event){return _vm.$emit('updateFilter', { value: $event })},"migrate":function($event){return _vm.$emit('updateFilter', $event)}}}):_vm._e()]},{"slotProps":{
        filter: _vm.filter,
        field: _vm.getField(_vm.filter.field),
        filterType: _vm.getFilterType(_vm.filter.type),
      }}),_vm._v(" "),_vm._t("afterValueInput",null,{"slotProps":{
        filter: _vm.filter,
        filterType: _vm.getFilterType(_vm.filter.type),
        emitUpdate: _vm.emitUpdate,
      }}),_vm._v(" "),(!_vm.fieldIdExists(_vm.fields, _vm.filter.field))?_c('i',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.$t('viewFilterContext.relatedFieldNotFound')),expression:"$t('viewFilterContext.relatedFieldNotFound')"}],staticClass:"fas fa-exclamation-triangle color-error"}):_vm._e(),_vm._v(" "),(!_vm.fieldIsCompatible(_vm.filter.type, _vm.filter.field))?_c('i',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.$t('viewFilterContext.filterTypeNotFound')),expression:"$t('viewFilterContext.filterTypeNotFound')"}],staticClass:"fas fa-exclamation-triangle color-error"}):_vm._e()],2),_vm._v(" "),_c('a',{staticClass:"filters__remove",class:{ 'filters__remove--disabled': _vm.disableFilter },on:{"click":function($event){!_vm.disableFilter && _vm.$emit('deleteFilter', $event)}}},[_c('i',{staticClass:"iconoir-bin"})])])
}
var staticRenderFns = []

export { render, staticRenderFns }